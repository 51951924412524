<template>
    <div class="navmb flex flexsb flexa">
        <div class="flex flexa">
            <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()">
                <Back />
            </el-icon> &nbsp; &nbsp;
            <span class="">文档详情</span>
        </div>
        <div class="right" v-if="state.audit == 1 && info.stasus == 1">
            <!-- state.audit&& -->
            <el-button type="danger"
                @click="statusData.show = true; statusData.form = { docId: info.id, stasus: 2, reason: '' }">
                <template #icon>
                    <el-icon :size="14" color="#fff">
                        <CircleCloseFilled />
                    </el-icon>
                </template>
                不通过审核
            </el-button>
            <el-button type="primary" @click="checkDoc(info, 3)">
                <template #icon>
                    <el-icon>
                        <SuccessFilled />
                    </el-icon>
                </template>
                通过审核
            </el-button>
        </div>
    </div>
    <el-dialog :close-on-click-modal="false" v-model="statusData.show" title="拒绝知识文档" width="520px">
        <el-form :model="statusData.form" ref="submitAudit" label-width="auto" :rules="statusData.rules">
            <el-form-item label="请输入拒绝理由" prop="reason">
                <el-input type="textarea" v-model="statusData.form.reason" placeholder="请输入拒绝理由" />
            </el-form-item>
        </el-form>

        <template #footer>
            <el-button v-loading="statusData.submitLoading" type="primary" @click="submit_audits()">确定</el-button>
            <el-button @click="statusData.show = false">取消</el-button>
        </template>
    </el-dialog>
    <p class="h20"></p>
    <div class="bgf padd30" v-loading="loading">
        <p class="col9 font14 www">
            文档类型：{{ nature.get(info.nature) }}
        </p>
        <div class="flex">
            <h1 class="padt14 font28 padb10">{{ info.docTitle }}
                <span class="padt14 font30 padb10 marl20" v-if="info.docNumber">(文档编号 {{ info.docNumber }}) </span>
            </h1>

            <div class="flex1"></div>
        </div>

        <div class="col9 font14 lh20 flexab padb30 www">
            <div>
                最近更新时间：{{ info.updateTime }} &nbsp;&nbsp;&nbsp;&nbsp;
                创建于 {{ info.createTime }}
            </div>

            <div class="flex flexa">
                <!-- <span class="padr40">文档编号 {{info.docNumber}} </span> -->

                <div class="flexa flex">
                    <img class="point" @click="clickCancel()" v-if="info.isCollect == 1"
                        :src="utils.loadImg('xing-a.png')" alt="" />
                    <img class="point" @click="collect.visible = true" v-else-if="info.isCollect == 0"
                        :src="utils.loadImg('xing.png')" alt="" />
                    &nbsp;&nbsp;
                    <span class="point" @click="$router.push({
                name: 'account'
            })">· 我的收藏</span>
                </div>
            </div>
        </div>

        <marktitle :text="info.docContent ?? ''"></marktitle>
        <getData class="font14 www col9">
            <div class="doc-info_top">
                文档所属分类：
                <template v-if="info.nature == 1">
                    <template v-for="item in info.docCategoryNameDtos">
                        <template v-for="(items, indexs) in item" :key="items.categoryId" >
                            <el-link @click="$router.push({
                                path: '/knowledge/docSearch',
                                state: {
                                    categoryId: items.categoryId,
                                }
                            })">{{ items.categoryName }}</el-link>
                            &nbsp;{{ indexs + 1 == item.length ? ';' : '>' }}&nbsp;
                        </template>
                    </template>

                </template>
                <template v-else>{{ info.categoryName }}</template>

            </div>
        </getData>
        <!-- <div class="col9 font14 lh20 flexab padb30 www">文档所属分类： {{ info.categoryName }}</div> -->
        <!-- <v-md-preview :text="text" ref="preview" ></v-md-preview> -->
    </div>
    <el-backtop :visibility-height="0" :right="100" :bottom="100" />
    <el-backtop :visibility-height="0" :right="100" :bottom="200" v-if="info.nature == 1">
        <div class="feedback-button" @click.prevent.stop="feedback.visible = true">
            <el-icon color="#000000" :size="20">
                <Edit />
            </el-icon>
            <span>文档反馈</span>
        </div>
    </el-backtop>
    <el-dialog :close-on-click-modal="false" title="添加到收藏夹" width="530px" v-model="collect.visible" align-center>
        <div class="ww100 flex flexsb">
            <p class="col9a font12 padb10">请选择你想要添加的收藏夹</p>
            <span class="point" style="color:#FF6A00"
                @click="$router.push({ path: '/account', query: { type: true } })">收藏夹管理</span>
        </div>
        <ul class="ul1">
            <li v-for="item in collect.list" :key="item.idstring">
                <span>{{ item.title }}（{{ item.number }}）</span>
                <el-button :loading="item.loading ?? false" @click="insertCollectCategory(item)" plain type="primary"
                    class="w68">收藏</el-button>
            </li>
        </ul>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="添加文档反馈建议" width="650px" v-model="feedback.visible" align-center>
        <p class="col9a font12 padb10">请添加你的反馈建议，帮助我们精确定位及解</p>
        <el-form :model="feedback.form" ref="submitForm" label-width="auto" :rules="feedback.rules">
            <el-form-item label="请输入反馈描述" prop="content">
                <el-input v-model="feedback.form.content" :rows="6" :autosize="{ minRows: 6, maxRows: 12 }"
                    type="textarea" placeholder="请输入文档反馈" />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button :loading="feedback.submitLoading ?? false" type="primary" @click="subDocFeedback">提交</el-button>
            <el-button @click="feedback.visible = false">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import { Back, Edit, SuccessFilled, CircleCloseFilled } from '@element-plus/icons-vue'
import marktitle from "@/components/marktitle.vue"
export default {
    setup() {
        return {
            docType: new Map([
                [1, '知识文档'],
                [2, 'Bug文档'],
            ]),
            nature: new Map([
                [1, '海量文档'],
                [2, '个人/公司文档'],
                [3, '个人文档'],
            ]),
            state: history.state
        }
    },
    components: {
        Back,
        Edit,
        SuccessFilled,
        CircleCloseFilled,
        marktitle
    },
    data() {
        return {
            loading: false,
            info: {},
            collect: {
                visible: false,
                list: []
            },
            feedback: {
                submitLoading: false,
                rules: {
                    content: [{ required: true, message: '请输入反馈内容', trigger: 'blur' }],
                },
                form: {
                    docId: this.$route.params.id
                },
                visible: false
            },
            statusData: {
                show: false, form: {}, submitLoading: false,
                rules: {
                    reason: [{ required: true, message: '请输入拒绝理由', trigger: 'blur' }],
                },
            },
        }
    },
    mounted() {
        this.userCollectionCategory()
        this.getData();
    },
    methods: {
        async submit_audits(validate = this.$refs['submitAudit'].validate) {
            try {
                await validate()
                this.statusData.submitLoading = true
                console.log(this.statusData.form)
                const result = await this.$api.document.checkDoc(this.statusData.form)
                if (result) {
                    this.$message.success('操作成功')
                    this.statusData.show = false
                    this.getData()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.statusData.submitLoading = false
            }
        },
        async checkDoc(row, stasus) {
            try {
                row.disable = true
                const result = await this.$api.document.checkDoc({
                    docId: row.id,
                    stasus
                })
                if (result) {
                    this.$message.success(result.message)
                    this.getData()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                row.disable = false
            }
        },
        async subDocFeedback() {
            try {
                this.feedback.submitLoading = true
                await this.$refs['submitForm'].validate()
                const result = await this.$api.document.subDocFeedback({
                    docId: this.feedback.form.docId,
                }, {
                    content: this.feedback.form.content,
                })
                if (result) {
                    this.feedback.visible = false
                    this.$message.success('感谢您对平台的反馈，我们会积极处理相关的反馈')
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.feedback.submitLoading = false
            }
        },
        async userCollectionCategory() {
            try {
                this.collect.list = await this.$api.user.userCollectionCategory()
                console.log(this.collect.list)
            } catch (e) {
                return console.log(e)
            }
        },
        async clickCancel() {
            let that = this
            const result = await that.$api.document.insertCollectCategory({ docId: this.$route.params.id })
            console.log(result)
            if (result) {
                that.$message.success(result.message)
                const res = await that.$api.know.getDocDetail({ id: this.$route.params.id })
                if (res) {
                    that.info = res
                    that.userCollectionCategory()
                }
            }
        },
        async insertCollectCategory(item) {
            try {
                item.loading = true
                console.log(item, 'item')
                const result = await this.$api.document.insertCollectCategory({
                    docId: this.$route.params.id,
                    collectionCategoryId: item.idstring
                })
                if (result) {
                    this.$message.success(result.message)
                    const res = await this.$api.know.getDocDetail({ id: this.$route.params.id })
                    if (res) {
                        this.info = res;
                        this.collect.visible = false
                        this.userCollectionCategory()
                    }
                }
            } catch (e) {
                return console.log(e)
            } finally {
                item.loading = false
            }
        },
        async getData() {
            try {
                this.loading = true
                const result = await this.$api.know.getDocDetail({ id: this.$route.params.id })
                console.log(result)
                if (result) {
                    this.info = result;
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.ul1 {
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
    }
}

.www {
    width: calc(100% - 250px);
}

.feedback-button {
    width: 42px;
    height: 124px;
    background: #FFFFFF;

    color: #242424;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 12px 8px;
    box-sizing: border-box;
    transition: box-shadow 0.4s;
}

.doc-info_top {
    .el-link {
        --el-link-text-color: #999;
        --el-link-font-size: 14px;
    }

    span {
        display: inline-block;
        vertical-align: top;
        margin-right: 35px;
        margin-bottom: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.feedback-button:hover {
    box-shadow: 0px 4px 9px 0px #E6E6E6;
    transition: box-shadow 0.4s;
}

.right {
    display: flex;
    align-items: center;
    padding-right: 20px;
}
</style>